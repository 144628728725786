<template>
  <b-modal
    id="modalCreateEditFornecedor"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="col-lg-12">
        <b-tabs
          justified
          nav-class="nav-tabs-custom"
          content-class="p-3 text-muted"
        >
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Dados</span>
            </template>

            <!-- NOME | TIPO DE CLIENTE | DATA DE INICIO -->
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Tipo: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{
                    'is-invalid': $v.fornecedor.tipo_fornecedor.$error,
                  }"
                  v-model="$v.fornecedor.tipo_fornecedor.$model"
                >
                  <option value="">Selecione o tipo de fornecedor</option>
                  <option value="2">Pessoa Física</option>
                  <option value="1">Pessoa Jurídica</option>
                </select>
                <span
                  v-if="!$v.fornecedor.tipo_fornecedor.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div
                v-if="fornecedor.tipo_fornecedor == 1"
                class="form-group col-sm-9"
              >
                <label>Nome fantasia: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.fornecedor.nome.$error }"
                  placeholder="Nome fantasia"
                  v-model="$v.fornecedor.nome.$model"
                />
                <span
                  v-if="!$v.fornecedor.nome.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="!$v.fornecedor.nome.minLength"
                  class="invalid-feedback"
                >
                  O nome deve possuir pelo menos 3 caracteres
                </span>
              </div>
              <div
                v-if="fornecedor.tipo_fornecedor == 2"
                class="form-group col-sm-9"
              >
                <label>Nome: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.fornecedor.nome.$error }"
                  placeholder="Nome completo"
                  v-model="$v.fornecedor.nome.$model"
                />
                <span
                  v-if="!$v.fornecedor.nome.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="!$v.fornecedor.nome.minLength"
                  class="invalid-feedback"
                >
                  O nome deve possuir pelo menos 3 caracteres
                </span>
              </div>
            </div>

            <!-- CPF/CNPJ | RAZÃO SOCIAL |  E RG -->
            <div class="form-row">
              <div
                v-if="fornecedor.tipo_fornecedor == 1"
                class="form-group col-sm-4"
              >
                <label>CNPJ: <span class="text-danger">*</span></label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.fornecedor.cnpj_cpf.$error }"
                  placeholder="00.000.000/000-00"
                  v-model="$v.fornecedor.cnpj_cpf.$model"
                  :mask="'##.###.###/####-##'"
                  :masked="true"
                />
                <span
                  v-if="!$v.fornecedor.cnpj_cpf.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="!$v.fornecedor.cnpj_cpf.minLength"
                  class="invalid-feedback"
                >
                  Formato inválido <br />
                  Ex: 00.000.000/0000-00 / 000.000.000-00
                </span>
              </div>
              <div
                v-if="fornecedor.tipo_fornecedor == 2"
                class="form-group col-sm-4"
              >
                <label>CPF: <span class="text-danger">*</span></label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.fornecedor.cnpj_cpf.$error }"
                  placeholder="000.000.000-00"
                  v-model="$v.fornecedor.cnpj_cpf.$model"
                  :mask="'###.###.###-##'"
                  :masked="true"
                />
                <span
                  v-if="!$v.fornecedor.cnpj_cpf.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="!$v.fornecedor.cnpj_cpf.minLength"
                  class="invalid-feedback"
                >
                  Formato inválido <br />
                  Ex: 00.000.000/0000-00 / 000.000.000-00
                </span>
              </div>
              <div
                v-if="fornecedor.tipo_fornecedor == 1"
                class="form-group col-sm-8"
              >
                <label>Razão social:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Razão Social"
                  v-model="fornecedor.razao_social"
                />
              </div>
            </div>

            <div v-if="fornecedor.tipo_fornecedor == 1" class="form-row">
              <div class="form-group col-sm-3">
                <label>Inscrição Municipal: </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Digite o número da I.M."
                  v-model="fornecedor.insc_municipal"
                />
              </div>
              <div class="form-group col-sm-3">
                <label>Inscrição Estadual: </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Digite o número da I.E."
                  v-model="fornecedor.insc_estadual"
                />
              </div>
            </div>

            <!-- EMAIL E CELULAR -->
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Telefone:</label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.fornecedor.telefone.$error }"
                  placeholder="(00) 98888-8888"
                  v-model="$v.fornecedor.telefone.$model"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :masked="true"
                />
                <span v-if="!$v.fornecedor.email" class="invalid-feedback">
                  Formato de e-mail inválido
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Celular:</label>
                <the-mask
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.fornecedor.celular.$error }"
                  placeholder="(00) 98888-8888"
                  v-model="$v.fornecedor.celular.$model"
                  :mask="['(##) ####-####', '(##) #####-####']"
                  :masked="true"
                />
                <span
                  v-if="!$v.fornecedor.celular.minLength"
                  class="invalid-feedback"
                >
                  Formato de telefone inválido <br />
                  Ex: (00) 98888-8888 / (00) 8888-8888
                </span>
              </div>
            </div>

            <!-- EMAIL E CELULAR -->
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>E-mail:</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="fornecedor.email"
                  placeholder="usuario@email.com.br"
                />
              </div>
              <div class="form-group col-sm-6">
                <label>Site:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="www.site.com.br"
                  v-model="fornecedor.site"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Observação:</label>
                <textarea
                  type="text"
                  class="form-control"
                  v-model="fornecedor.observacao"
                />
              </div>
              <div v-if="fornecedorId" class="form-group col-sm-6">
                <p>Status: <span class="text-danger">*</span></p>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusInativo"
                    value="0"
                    v-model="fornecedor.status"
                  />
                  <label class="form-check-label" for="statusInativo">
                    Inativo
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusAtivo"
                    value="1"
                    v-model="fornecedor.status"
                  />
                  <label class="form-check-label" for="statusAtivo">
                    Ativo
                  </label>
                </div>
              </div>
            </div>
          </b-tab>

          <!-- ENDEREÇO -->
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Endereço</span>
            </template>
            <div class="form-row">
              <div class="form-group col-sm-8">
                <label>Logradouro:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Rua, Av., Tv..."
                  v-model="fornecedor.logradouro"
                />
              </div>
              <div class="form-group col-sm-4">
                <label>Nº: </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nº"
                  v-model="fornecedor.numero"
                />
              </div>
            </div>

            <!-- BAIRRO, ESTADO E CIDADE -->
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>Bairro:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nome do bairro"
                  v-model="fornecedor.bairro"
                />
              </div>
              <div class="form-group col-sm-4">
                <label>Estado:</label>
                <select class="form-control" v-model="fornecedor.city_state_id">
                  <option value="">Selecione o estado</option>
                  <option
                    v-for="state in states"
                    :key="state.id"
                    :value="state.id"
                    :title="`${state.uf} - ${state.name}`"
                  >
                    {{ state.uf }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-4">
                <label>Cidade: </label>
                <select class="form-control" v-model="fornecedor.city_id">
                  <option value="">Selecione uma cidade</option>
                  <option
                    v-for="city in cities"
                    :key="city.id"
                    :value="city.id"
                    :title="city.name"
                  >
                    {{ city.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- ENDEREÇO -->
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>País:</label>
                <select class="form-control" v-model="fornecedor.pais_id">
                  <option value="">Selecione um pais</option>
                  <option
                    v-for="pais in paises"
                    :key="pais.id"
                    :value="pais.id"
                    :title="pais.pais_nome"
                  >
                    {{ pais.pais_nome }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-6">
                <label>Complemento:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Complemento do endereço"
                  v-model="fornecedor.complemento"
                />
              </div>
              <div class="form-group col-sm-2">
                <label>CEP:</label>
                <the-mask
                  type="text"
                  class="form-control"
                  placeholder="00000-000"
                  v-model="fornecedor.cep"
                  :mask="'#####-###'"
                  :masked="true"
                />
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Contatos</span>
            </template>
            <div>
              <button
                type="button"
                @click="addContato()"
                class="btn btn-primary"
                title="Adicionar rasgadinha"
              >
                <i class="bx ri-add-line"></i> Novo contato
              </button>
            </div>
            <div role="tablist">
              <div
                v-for="(etapa, index) in contatos"
                :key="index"
                class="card task-box"
              >
                <b-card no-body class="mb-1 shadow-none">
                  <b-card-header header-tag="header" role="tab">
                    <div class="form-row">
                      <div class="form-group col-sm-1">
                        <br />
                        <a
                          v-b-toggle="'accordion-' + index"
                          class="btn btn-lg btn-rounded btn-outline-info"
                          href="javascript: void(0);"
                          >{{ index + 1 }}</a
                        >
                      </div>
                      <div class="form-group col-sm-6">
                        <label>Nome: <span class="text-danger">*</span></label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Nome completo"
                          :name="'contatos[' + index + '].[nome]'"
                          :id="'contatos[' + index + ']'"
                          v-model="contatos[index].nome"
                        />
                      </div>
                      <div class="form-group col-sm-4">
                        <label>Aniversário:</label>
                        <input
                          type="date"
                          class="form-control"
                          placeholder="Data de Nascimento"
                          :name="'contatos[' + index + '].[aniversario]'"
                          :id="'contatos[' + index + ']'"
                          v-model="contatos[index].aniversario"
                        />
                      </div>
                      <div class="form-group col-sm-1">
                        <br />
                        <button
                          type="button"
                          @click="deleteContato(index)"
                          class="btn btn-lg btn-outline-danger"
                          title="Adicionar item"
                        >
                          <i class="bx ri-delete-bin-line"></i>
                        </button>
                      </div>
                    </div>
                  </b-card-header>
                  <b-collapse
                    :id="'accordion-' + index"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <!-- EMAIL E CELULAR -->
                      <div class="form-row">
                        <div class="form-group col-sm-4">
                          <label>E-mail: </label>
                          <input
                            type="email"
                            class="form-control"
                            :name="'contatos[' + index + '].[email]'"
                            :id="'contatos[' + index + ']'"
                            v-model="contatos[index].email"
                            placeholder="usuario@email.com.br"
                          />
                          <span
                            v-if="!$v.fornecedor.email"
                            class="invalid-feedback"
                          >
                            Formato de e-mail inválido
                          </span>
                        </div>
                        <div class="form-group col-sm-4">
                          <label>Telefone:</label>
                          <the-mask
                            type="text"
                            class="form-control"
                            placeholder="(00) 0000-0000"
                            :name="'contatos[' + index + '].[telefone]'"
                            :id="'contatos[' + index + ']'"
                            v-model="contatos[index].telefone"
                            :mask="['(##) ####-####']"
                            :masked="true"
                          />
                        </div>
                        <div class="form-group col-sm-4">
                          <label>Celular:</label>
                          <the-mask
                            type="text"
                            class="form-control"
                            placeholder="(00) 98888-8888"
                            :name="'contatos[' + index + '].[celular]'"
                            :id="'contatos[' + index + ']'"
                            v-model="contatos[index].celular"
                            :mask="['(##) ####-####', '(##) #####-####']"
                            :masked="true"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-sm-6">
                          <label>Cargo:</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Descrição do cargo do contato"
                            :name="'contatos[' + index + '].[cargo]'"
                            :id="'contatos[' + index + ']'"
                            v-model="contatos[index].cargo"
                          />
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'CreateEditFornecedorModal',

  components: {
    TheMask,
  },

  props: {
    fornecedorId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      fornecedor: {
        city_id: '',
        city_state_id: '',
        nome: '',
        cnpj_cpf: '',
        tipo_fornecedor: 2,
        razao_social: '',
        natureza_tributaria: '',
        fornecedor_data_inicio: '',
        insc_estadual: '',
        insc_municipal: '',
        natureza_juridica: '',
        telefone: '',
        email: '',
        celular: '',
        site: '',
        logradouro: '',
        numero: '',
        bairro: '',
        cep: '',
        pais_id: '',
        complemento: '',
        qualificação: '',
        agencia: '',
        conta: '',
        banco_id: '',
        observacao: '',
      },
      contatos: [],
      disableButton: false,
      states: null,
      cities: null,
      paises: null,
      cities_responsavel: null,
      loadDataEditing: false,
    }
  },

  computed: {
    textModal() {
      return this.fornecedorId ? 'Atualizar' : 'Cadastrar'
    },
  },

  watch: {
    'fornecedor.city_state_id': {
      handler(newValue, oldValue) {
        if (!this.loadDataEditing) {
          this.fornecedor.city_id = ''
        }
        if (newValue !== '') {
          this.getCities()
        }
      },
    },
  },

  methods: {
    addContato() {
      this.contatos.push({
        nome: '',
        aniversario: '',
        email: '',
        telefone: '',
        celular: '',
        cargo: '',
      })
    },
    deleteContato(index) {
      this.contatos.splice(index, 1)
    },

    async getPaises() {
      try {
        this.paises = await this.$store.dispatch('getPaises')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getStates() {
      try {
        this.states = await this.$store.dispatch('getStates')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCities() {
      try {
        this.cities = await this.$store.dispatch(
          'getCities',
          this.fornecedor.city_state_id,
        )
        this.loadDataEditing = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getFornecedor() {
      try {
        this.loadDataEditing = true
        this.fornecedor = await this.$store.dispatch(
          'getFornecedor',
          this.fornecedorId,
        )
        this.contatos = await this.$store.dispatch(
          'getContatosByFornecedor',
          this.fornecedorId,
        )
        this.$bvModal.show('modalCreateEditFornecedor')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.fornecedorId) {
            response = await this.$store.dispatch('updateFornecedor', {
              id: this.fornecedorId,
              fornecedor: this.fornecedor,
              contatos: this.contatos,
            })
          } else {
            response = await this.$store.dispatch('saveFornecedor', {
              fornecedor: this.fornecedor,
              contatos: this.contatos,
            })
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    fornecedor: {
      nome: {
        required,
        minLength: minLength(3),
      },
      cnpj_cpf: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(18),
      },
      telefone: {
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
      celular: {
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
      tipo_fornecedor: { required },
    },
  },

  mounted() {
    this.getStates()
    this.getPaises()
    if (this.fornecedorId) {
      this.getFornecedor()
    } else {
      this.$bvModal.show('modalCreateEditFornecedor')
    }
  },
}
</script>
